<template>
  <div class="content">
    <div class="items" v-for="msg in messages">
      <div class="flex align-items-center">
        <div class="icon" :class="`${msg.type}`">
            <img src='../../../assets/icons/notification/medical.png' alt="" v-if="msg.type === 'medical'" />
            <img src='../../../assets/icons/notification/business.png' alt="" v-if="msg.type === 'business'" />
            <img src='../../../assets/icons/notification/device.png' alt="" v-if="msg.type === 'device'" />
            <img src='../../../assets/icons/notification/exam.png' alt="" v-if="msg.type === 'exam'" />
            <img src='../../../assets/icons/notification/favorite.png' alt="" v-if="msg.type === 'favorite'" />
        </div>
        <div class="msg">
          <p>{{msg.msg}}</p>
          <p class="alert">{{msg.alert}}</p>
        </div>
      </div>
      <div class="action">
        <img src="../../../assets/icons/notification/action.png" alt="">
      </div>
    </div>
    <div class="previous">
      <p>Ver anteriores</p>
      <img src="../../../assets/icons/notification/down.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const messages = [
        {
            msg: "Nova consulta marcada para o dia 19", 
            alert: "Você tem uma consultada marcada na Clínica Moura",
            type: "medical"
        },
        {
            msg: "Novo login efetuado às 19:22", 
            alert: "Caso não tenha sido você clique aqui",
            type: "device"
        },
        {
            msg: "Você tem um novo saque disponível", 
            alert: "Confira aqui os valores liberados",
            type: "business"
        },
        {
            msg: "Confira nossas atualizações!", 
            alert: "Agora você pode fazer mais saques por mês",
            type: "favorite"
        },
        {
            msg: "Resultado de exame de sangue disponível!", 
            alert: "Exame de Gabriel Godim do dia 23 de Janeiro",
            type: "exam"
        },
    ]
    return { messages };
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 50px;
  right: -320px;
  width: 420px;
  padding: 16px;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(81.5485px);
  border-radius: 8px;
  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background: #fafafa;
    padding: 14px 8px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    .icon {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 80px;
    }
    .msg {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      .alert {
        font-size: 12px;
        color: #2f80ed;
      }
    }
    .medical {
      background: #bb6bd9;
    }
    .business {
      background: #219653;
    }
    .exam {
      background: #2F80ED;
    }
    .device, .favorite {
      background: #FF6A33;
    }
    .action {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
    }
  }
  .previous{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    font-size: 12px;
    color: #2F80ED;
    border: 1px solid #FFFFFF;
    border-radius: 80px;
    font-weight: 400;
  }
}
</style>

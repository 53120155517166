<template>
  <div class="profile" @click="toggleDropdown">
    <img class="img-profile" src="../../assets/icons/upload-default.png" v-if="!avatar" />
    <img class="img-profile" :src="getAvatarUrl" v-else />
    <span style="margin-left: 12px; margin-right: 12px">{{ name ? name : "-" }}</span>
    <img class="btn-dropdown" src="../../assets/icons/drop_down.png"  />
  </div>
</template>

<script>
import { computed } from "vue"
export default {
  name: "ProfessionalDropdown",
  props: ["name", "avatar"],
  emits: ["toggleMenu"],
  setup(props, {emit}) {
    const getAvatarUrl = computed(() => {
      return process.env.VUE_APP_BUCKET_URL + '/' + props.avatar
    })

    const toggleDropdown = (event) => {
      emit("toggleMenu", event);
    }

    return{ toggleDropdown, getAvatarUrl }
  }
};
</script>
<style lang="scss" scoped>
.p-menu {
  margin-top: 1vh;
  padding: 0;
  .p-menu-separator {
    border-color: #f8f8f9;
    margin: 0 !important;
  }
}
.img-profile{
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.p-dialog {
  border-radius: 15px !important;

  .p-dialog-header {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    justify-content: center;
  }

  .p-dialog-content {
    justify-content: center;
    display: flex;
  }

  .p-dialog-footer {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
}

.p-menu:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 8px 8px 0;
  transform: rotateX(178deg);
  border-color: #fff transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -7px;
  right: 10px;
}
.btn-dropdown {
  padding: 12px 6px;
  border-radius: 8px;
  cursor: pointer;
}
.p-menuitem {
  font-weight: bold;
  height: 5vh;
  padding: 0;
  margin: 0;
  color: red;
}
.p-menu-list li a {
  border-radius: 10px;
}
.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ff6a33;
  box-shadow: 0px 0px 16px rgba(61, 71, 102, 0.08);
  backdrop-filter: blur(8px);
  border-radius: 41.5px;
  margin-left: 8px;
  min-width: 160px;
  height: 36px;
  padding-right: 12px;
  cursor: pointer;
  img {
    margin-left: 4px;
  }
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: white;
}
@media (max-width: 924px) {
  .profile{
    min-width: max-content !important;
    span{
      display: none !important;
    }
  }
}
</style>

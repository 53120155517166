import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "app-menu" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "icon-menu" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menu.items, (item) => {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["row-menu items", item.to === $setup.menu.pathName ? 'active' : 'noactive']),
        onClick: ($event: any) => ($setup.navigate(item.to))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: item.icon,
            class: _normalizeClass(item.to === $setup.menu.pathName ? 'active' : 'noactive')
          }, null, 10, _hoisted_4)
        ]),
        _createTextVNode(" " + _toDisplayString(item.label), 1)
      ], 10, _hoisted_2),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (sub) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["sub-items", sub.to === $setup.menu.pathName ? 'active' : 'noactive']),
            onClick: ($event: any) => ($setup.navigate(sub.to))
          }, _toDisplayString(sub.label), 11, _hoisted_5))
        }), 256))
      ])
    ]))
  }), 256))
}
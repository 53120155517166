<template>
  <div class="layout-topbar">
    <div class="row">
      <Button class="btn-toggle" icon="pi pi-bars" @click="toggleMenu" />
      <div class="search-bar">
        <span class="search-item p-input-icon-right">
          <i class="pi pi-search" />
          <InputText class="input-search" type="text" v-model="searchBar" placeholder="O que você procura?" />
        </span>
      </div>
    </div>

    <div class="topbar-right">
      <ul class="topbar-menu">
        <li class="icon-menu">
          <div class="notification-bg">
            <div class="color-notification" />
          </div>
          <div class="icon" @click="toggleActions('chat')">
            <img :class="isAction('chat')" src="../../../assets/icons/chat.png" />
          </div>
          <Chat v-if="boolActions.chat" />
        </li>
        <li class="icon-menu icon-notification">
          <div class="notification-bg">
            <div class="color-notification" />
          </div>
          <div class="icon" @click="toggleActions('notification')">
            <img :class="isAction('notification')" src="../../../assets/icons/bell.png" />
          </div>

          <Notification v-if="boolActions.notification" />
        </li>
        <li class="btn-qrcode">
          <img class="qrcode-icon" src="../../../assets/icons/qrcode.png" /> QR CODE
        </li>
        <div>
          <ProfessionalDropdown :avatar="avatar" :name="name" @toggleMenu="toggleActions('profile')" />
          <Profile v-if="boolActions.profile" @toggleMenu="toggleActions('profile')" />
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import ProfessionalDropdown from "../../../components/professional/ProfessionalDropdown.vue";
import professionalProfileService from "../../core/services/professionalProfileService";
import useStore from "../../../store";
import { useMenu } from '../../../store/menu.js'
import Notification from "./Notification";
import Chat from "./Chat/Chat.vue";
import Profile from "./Profile/Profile.vue";

export default {
  name: "AppTopbar",
  components: { ProfessionalDropdown, Notification, Chat, Profile },
  props: {
    topbarNotificationMenuActive: Boolean,
    topbarMessageMenuActive: Boolean,
    topbarUserMenuActive: Boolean,
  },
  setup(props, { emit }) {
    const menu = useMenu();
    const store = useStore();
    let name = ref("");
    let avatar = ref("");
    let boolActions = ref({
      notification: false,
      chat: false,
      profile: false,
    });

    const toggleMenu = (event) => {
      emit("toggle-menu", event.target.value);
    };

    const toggleActions = (name) => {
      const oldValue = boolActions.value[name];
      boolActions.value = {
        notification: false,
        chat: false,
        profile: false,
      };
      boolActions.value[name] = !oldValue;
    };

    const isAction = name => {
      const bool = boolActions.value
      if (bool.notification || bool.chat) {
        if (bool[name]) {
          return ''
        } else {
          return 'inative'
        }
      }
      return ''
    }

    const ddProfile = () => {
      console.log("funfou");
    }

    onMounted(async () => {
      try {
        const res = await professionalProfileService.getInfo();
        const data = JSON.parse(res.data);
        // console.log(data)
        avatar.value = data.pictureUrl
        name.value = data.firstName;
      } catch (error) {
          // console.log(error)
          if(error.message == "Acesso não autorizado"){
            window.localStorage.removeItem('token');
            menu.logout();
            console.log("Acesso não autorizado");
          }
      }
    });

    return {
      toggleMenu,
      name,
      items: [],
      searchBar: "",
      visibleRight: false,
      professional: {
        name: store.userInfo.firstName + " " + store.userInfo.lastName,
      },
      boolActions,
      toggleActions,
      isAction,
      ddProfile,
      avatar,
    };
  },
};
</script>

<style lang="scss" scoped>
.layout-topbar {
  background: #fafafa;
  border-bottom: 0;
  position: relative;
  margin: 29px 0 12px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;  
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.btn-toggle {
  height: 40px !important;
  display: none !important;
  width: 40px !important;
  padding-left: 3px !important;

  @media (max-width: 830px) {
    display: block !important;
    text-align: center !important;
  }
}

.search-bar {
  @media (max-width: 830px) {
    display: none !important;
  }
}


.input-search {
  border: none;
  max-width: 100%;
  height: 35px;
  padding-left: 40px !important;
  border-radius: 8px;

  @media (max-width: 830px) {
    display: none;
  }
}

.topbar-menu li {
  position: relative;
  margin-left: 0px !important;
}

.notification-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 7px;
  right: 7px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #f1f5f8;
  z-index: 9999999;
  cursor: pointer;

  .color-notification {
    background-color: #ff4500;
    border-radius: 100%;
    width: 8px;
    height: 8px;
  }
}

.topbar-menu {
  display: flex;
  align-items: center;

  .icon-notification {
    margin-right: 16px;
  }

  .icon-menu {
    padding: 18px;

    .icon {
      padding: 10px;
      cursor: pointer;

      &:hover {
        background: #ededed;
        border-radius: 8px;
      }
    }

    .inative {
      filter: invert(92%) sepia(0%) saturate(5536%) hue-rotate(313deg) brightness(133%) contrast(76%);
    }
  }

  li {
    display: flex;
    align-items: center !important;

    a {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      height: max-content;
    }
  }
}

.btn-qrcode {
  display: flex;
  gap: 8px;
  padding: 10px 17px;
  align-items: center;
  background: #bdbdbd;
  border-radius: 41.5px;
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}

.topbar-right {
  position: relative;
}

.inative {
  filter: invert(92%) sepia(0%) saturate(5536%) hue-rotate(313deg) brightness(133%) contrast(76%);
}

::v-deep(.input-search) {
  padding: 0;
  padding-left: 0;
  margin: 0;
}

::v-deep(.search-bar) {
  display: flex;
  width: 475px;
  max-width: 100%;

  .pi-search {
    color: white !important;
    font-size: 12px;
    background: #ff6a33;
    padding: 12px;
    border-radius: 100%;
    margin: 0;
    top: 4px;
    right: 0px;
    margin-right: 2px;
    cursor: pointer;

    @media (max-width: 830px) {
      display: none;
    }
  }
}

::v-deep(.p-inputtext) {
  padding-left: 20px !important;
  padding-right: 45px !important;
  margin: 0;
  border-radius: 22px;
  height: 44px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  &::placeholder {
    color: #bdbdbd;
  }
}

:deep() {
  .search-item {
    width: 100% !important;
  }
}
</style>

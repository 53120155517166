
import { ref } from "vue";
import { useMenu } from '../../../store/menu.js'

export default {
  emits:["close-menu"],
  setup(props, { emit }) {
    const menu = useMenu()

    const navigate = (path) => {
      if (path) {
        menu.changeRoute(path)
        emit("close-menu", true);
      }
    }

    return {
      menu,
      navigate,
    }
  }
};

<template>
  <div class="layout">
    <div class="layout-left" :class="isVisible">
      <div class="logo">
        <img src="/assets/images/logo-ommed.png" alt="OMMED" />
        <Button
          class="close-modal btn-toggle"
          icon="pi pi-bars"
          @click="closeMenu"
        />
      </div>
      <AppSidebar @close-menu="closeMenu" />
    </div>

    <div class="layout-right" :class="!isVisible">
      <AppTopbar @toggle-menu="toggleMenu" />
      <router-view />
    </div>
  </div>
</template>

<script>
import AppTopbar from "./AppTopbar";
import AppSidebar from "./AppSidebar.vue";
import { ref } from "vue";

export default {
  name: "AppLayout",
  components: {
    AppTopbar,
    AppSidebar,
  },
  setup() {
    const visibleMenu = ref(false);
    const isVisible = ref(visibleMenu.value ? "block" : "none");

    const toggleMenu = () => {
      visibleMenu.value = !visibleMenu.value;
      console.log(visibleMenu.value);
      isVisible.value = visibleMenu.value ? "block" : "none";
    };

    const closeMenu = () => {
      visibleMenu.value = false;
      isVisible.value = "none";
    };

    return {
      visibleMenu,
      isVisible,
      toggleMenu,
      closeMenu,
    };
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: flex;
  justify-content: center !important;
  padding: 0 !important;
  width: 100%;
  height: 60px !important;
  align-items: center;
  margin: 0 !important;
}
.layout{
  max-width: 1400px !important;
  margin: 0 auto;
}
.layout-left {
  position: relative;
  display: flex !important;
  justify-content: flex-start;
  height: max-content !important;
  @media (max-width: 830px) {
    /* display: none !important; */
    /* margin-left: -250px; */
    position: fixed !important;
    top: 0px;
    bottom: 0px;
    overflow-y: scroll;
    max-height: 100vh;
    position: absolute;
    z-index: 999999;
    width: 100%;
    height: 100%;
    padding-bottom: 120px;
  }
  .logo{
    margin-top: 26px !important;
    margin-bottom: 12px !important;
  }
}
.layout-right {
  width: calc(100% - 250px);
  @media (max-width: 830px) {
    width: 100%;
  }
}
.close-modal {
  position: absolute;
  top: 8px;
  left: 28px;
  display: none;
  @media (max-width: 830px) {
    display: block;
  }
}
.none {
  @media (max-width: 830px) {
    display: none !important;
  }
}
.block {
  @media (max-width: 830px) {
    display: block !important;
  }
}
.btn-toggle {
  height: 40px !important;
  display: none !important;
  width: 40px !important;
  padding-left: 3px !important;
  @media (max-width: 830px) {
    display: block !important;
    text-align: center !important;
  }
}
</style>

<template>
  <div class="content">
    <h1>Você deseja sair?</h1>
    <p class="msg">Ao clicar em continuar você terá que fazer login novamente.</p>
    <div class="flex justify-content-center" style="gap: 16px;">
      <button class="gray" @click="$emit('logout', false)">
        <p>Voltar</p>
        <img src="../../../../assets/icons/menu/back.png" alt="">
      </button>
      <button @click="logout">
        <p>Continuar</p>
        <img src="../../../../assets/icons/menu/continue.png" alt="">
      </button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useMenu } from "../../../../store/menu.js"

export default {
  setup() {
    const menu = useMenu();
    const router = useRouter();

    const logout = () => {
      window.localStorage.removeItem("token");
      menu.logout();
    };

    return { logout };
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 50px;
  right: 0;
  width: 420px;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(81.5485px);
  border-radius: 8px;
  button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    font-size: 12px;
    color: #2f80ed;
    border: 1px solid #ffffff;
    border-radius: 80px;
    font-weight: 400;
    width: max-content;
    gap: 16px;
    cursor: pointer;
  }
  .gray {
    color: #5b5b5b;
  }
  .msg {
    max-width: 250px;
    text-align: center;
    margin: 80px auto;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    color: #2D313D;
  }
  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin: 0;
  }
  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background: #fafafa;
    padding: 14px 8px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    .icon {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 80px;
      background: #3d4766;
    }
    .msg {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      .alert {
        font-size: 12px;
        color: #2f80ed;
      }
    }
    .medical {
      background: #bb6bd9;
    }
    .business {
      background: #219653;
    }
    .exam {
      background: #2f80ed;
    }
    .device,
    .favorite {
      background: #ff6a33;
    }
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
    }
  }
  .previous {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    font-size: 12px;
    color: #2f80ed;
    border: 1px solid #ffffff;
    border-radius: 80px;
    font-weight: 400;
  }
}
</style>

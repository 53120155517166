import { defineStore } from 'pinia';
import router from '../router';
import { Login, PhoneConfirmation } from '../modules/core/actions/professionalAccount.ts'
import { GetInfo } from '../modules/core/actions/professionalProfile.ts'

export default defineStore('store', {
    state: () => {
        return {
            token: localStorage.getItem('token') || '',
            userInfo: {},
            screenResponse: false,
            email: localStorage.getItem('email') || '',
            error: '',
        }
    },
    getters: {
        isLoggedIn: (state) => !!state.token
    },
    actions: {
        async login(username, password) {
            const obj = { login: username, password: password };
            const response = await Login(obj);

            if (response?.success) {
                this.error = '';
                const data = JSON.parse(response.data);
                localStorage.setItem('token', data.token);
                const resGetInfo = await GetInfo();

                if (!data.emailConfirmed) {
                    localStorage.setItem('email', data.email);
                } else {
                    localStorage.removeItem('email');
                }

                if (!data.phoneConfirmed) {
                    localStorage.setItem('phone', data.phone);
                } else {
                    localStorage.removeItem('phone');
                }
                

                if (resGetInfo?.success) {
                    if(resGetInfo?.data){
                        const data = JSON.parse(resGetInfo?.data);
                        this.userInfo = data;
                    }
                }
                this.checkScreen(data.screen);
            } else {
                this.error = response;
            }
        },
        logout() {
            localStorage.removeItem('token');
            this.token = '';
            this.userInfo = {};
            router.push('/account/login');
        },
        async checkScreen(data) {
            switch(data) {
                case 2: 
                    const phone = localStorage.getItem('phone')
                    const response = await PhoneConfirmation(phone);

                    if (response?.success) {
                        router.push('/account/phone/confirmation');
                    }
                break;
                case 3: 
                    router.push('/account/infos');
                break;                
                case 4:
                    router.push('/account/records');
                break;                
                case 5:
                    router.push('/account/upload');
                break;                
                case 6: 
                    router.push('/account/presentation');
                break;                
                case 7:
                    router.push('/')
                break;
                case 8:
                    // this.verifyEmail();
                    // router.push('/');
                    if(localStorage.getItem('email')) {
                        // this.screenResponse = true;
                        router.push('/account/email/confirmation');
                    }else {
                        router.push('/');
                    }
                break;
                
                default:
                    console.log('Desculpe, mas algo deu errado para não entrar em nenhum CASE');
            }
        },
    }
});
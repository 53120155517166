<template>
  <Logout @logout="logout = false" v-if="logout" />
  <div class="content" v-else>
    <div class="items" v-for="item in items" @click="selectItem(item.to)">
      <div class="flex align-items-center">
        <div class="icon">
            <img src='../../../../assets/icons/menu/my-profile.png' alt="" v-if="item.type === 'my-profile'" />
            <img src='../../../../assets/icons/menu/settings.png' alt="" v-if="item.type === 'settings'" />
            <img src='../../../../assets/icons/menu/help.png' alt="" v-if="item.type === 'help'" />
            <img src='../../../../assets/icons/menu/team.png' alt="" v-if="item.type === 'my_team'" />
        </div>
      </div>
      {{item.item}}
      <div class="action">
        <img src="../../../../assets/icons/menu/to.png" alt="">
      </div>
    </div>
    <div class="logout" style="margin-top: 100px;" @click="logout = true">
      <p>Sair da conta</p>
      <img src="../../../../assets/icons/menu/logout.png" alt="">
    </div>
  </div>
  
</template>

<script>
import {ref} from "vue";
import { useRouter } from "vue-router";
import { useMenu } from "../../../../store/menu.js"
import Logout from "./Logout"

export default {
  name: "Profile",
  components: {
    Logout
  },
  emits: ["toggleMenu"],
  setup(_, { emit }) {
    const menu = useMenu();
    const router = useRouter();
    const logout = ref(false);
    const items = [
        {
            item: "Meu perfil",
            type: "my-profile",
            to: "/my-profile"
        },
        {
            item: "Configurações",
            type: "settings",
            to: "/settings"
        },
        {
            item: "Suporte",
            type: "help",
            to: "/help"
        },
        {
            item: "Minha equipe",
            type: "my_team",
            to: "/team"
        },
    ]

    const selectItem = to => {
      redirect(to);
      emit("toggleMenu", true)
    }

    const redirect = to => {
      router.push({ path: to });
      menu.changeRoute(to)
    }

    return { logout, items, selectItem, redirect };
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 50px;
  right: 0;
  width: 420px;
  padding: 16px;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(81.5485px);
  border-radius: 8px;
  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background: #fafafa;
    padding: 14px 8px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    .icon {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 80px;
      background: #3d4766;
    }
    .msg {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      .alert {
        font-size: 12px;
        color: #2f80ed;
      }
    }
    .medical {
      background: #bb6bd9;
    }
    .business {
      background: #219653;
    }
    .exam {
      background: #2F80ED;
    }
    .device, .favorite {
      background: #FF6A33;
    }
    .action {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
    }
  }
  .logout{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    font-size: 12px;
    color: #2F80ED;
    border: 1px solid #FFFFFF;
    border-radius: 80px;
    font-weight: 400;
    cursor: pointer;
  }
}
</style>

<template>
  <ListMessages v-model:screen="screen" v-if="screen === 'List-Messages'" />
  <ChatMessage  v-model:screen="screen" v-if="screen === 'Chat-Message'" />
</template>

<script>
import { ref } from "vue";
import ListMessages from "./ListMessages.vue";
import ChatMessage from "./ChatMessage.vue";

export default {
  components: {
    ListMessages,
    ChatMessage,
  },
  setup() {
    const screen = ref("List-Messages");

    return {screen};
  },
};
</script>

<style lang="scss" scoped></style>

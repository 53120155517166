<template>
  <div class="content">
    <div class="items" v-for="(msg, index) in messages" :key="index" @click="seeMessage()">
      <div class="flex align-items-center">
        <div class="icon">
          <img :src="msg.img" alt="" />
        </div>
        <div class="msg">
          <p>{{ msg.name }}</p>
          <p class="msg" :class="!msg.read && 'noread'">{{ msg.msg }}</p>
        </div>
      </div>
      <div class="flex align-items-center" style="gap: 8px;">
        <p class="hour" :class="msg.read && 'hour-read'">{{ msg.hour }}</p>
        <div class="action">
          <img :class="msg.read && 'read'" src="../../../../assets/icons/notification/action.png" alt="" />
        </div>
      </div>
    </div>
    <span class="search-chat p-input-icon-right">
      <i class="pi pi-search" />
      <InputText type="text" v-model="search" placeholder="Search" />
    </span>
    <div class="previous">
      <p>Ver mensagens de ontem</p>
      <img src="../../../../assets/icons/notification/down.png" alt="" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import img1 from "../../../../assets/avatares/patients/1.png"
import img2 from "../../../../assets/avatares/patients/6.png"
import img3 from "../../../../assets/avatares/patients/3.png"
import img4 from "../../../../assets/avatares/patients/11.png"

export default {
  props: ["screen"],
  emits: ["update:screen"],
  setup(_, {emit}) {
    const search = ref();
    const messages = [
      {
        name: "Renato Filho",
        msg: "Eu tenho uma dúvida sobre o medicamento...",
        hour: "14:30",
        img: img1,
        read: false,
      },
      {
        name: "Carla Fonseca Mattos",
        msg: "Eu já estou perto, um momento dr",
        hour: "13:11",
        img: img2,
        read: true,
      },
      {
        name: "Júlia Castro",
        msg: "Você: Obrigado Júlia!",
        hour: "13:10",
        img: img3,
        read: true,
      },
      {
        name: "Matheus Dória Junior",
        msg: "Você: Fico no aguardo do seu retorno ...",
        hour: "13:10",
        img: img4,
        read: true,
      },
    ];

    const seeMessage = () => {
      console.log("Ver mensagem")
      emit("update:screen", "Chat-Message");
    }

    return { search, messages, seeMessage };
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 50px;
  right: -320px;
  width: 420px;
  padding: 16px;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(81.5485px);
  border-radius: 8px;
  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background: #fafafa;
    padding: 14px 8px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    .hour{
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      color: #828282;
    }
    .hour-read{color: #BDBDBD;}
    .read{
      filter: invert(81%) sepia(9%) saturate(3%) hue-rotate(46deg) brightness(92%) contrast(93%);
    }
    .icon {
      width: 32px;
      height: 32px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 80px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .msg {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      .msg{margin-left: 0;font-size: 12px;color: #BDBDBD;}
      .noread {color: #2f80ed;}
    }
    .medical {
      background: #bb6bd9;
    }
    .business {
      background: #219653;
    }
    .exam {
      background: #2f80ed;
    }
    .device,
    .favorite {
      background: #ff6a33;
    }
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
    }
  }
  .previous {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    font-size: 12px;
    color: #2f80ed;
    border: 1px solid #ffffff;
    border-radius: 80px;
    font-weight: 400;
  }
}
::v-deep(.p-input-icon-right) {
  margin-top: 30px !important;
}
::v-deep(.p-inputtext) {
  height: 36px !important;
}
::v-deep(input) {
  background: none !important;
  color: #828282;
  display: flex;
  max-width: 662px !important;
  border-color: white;
  &::placeholder {
    color: #828282;
  }
}
::v-deep(.search-chat) {
  .pi-search{
    background: none !important;
    display: flex;
    max-width: 662px !important;
    color: #828282 !important;
    margin-right: 8px;
    font-size: 12px;
  }
  
}
</style>
